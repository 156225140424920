import React, { Component, useState } from 'react';
import { withStyles, Typography, Button, AppBar, Grid, MenuItem, IconButton, Drawer, Toolbar, Hidden } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';


import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';

import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';

import { Link } from "react-router-dom";
//media imports
import logo from '../logo.png'


const styles = theme => ({
    placeholder: {
        display: 'block'
    },
    root: {
        flexGrow: 1,
        width: '100%',
        alignContent: 'center',
    },
    toggle: {
        color: 'black',
        size: 'large'
    },
    logoTxt: {
        float: 'right',
        height: 'auto',
        color: '#47ADDC',
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        fontSize: 20,

    },
    logoTxt2: {
        float: 'right',
        height: 'auto',
        color: '#9DE1F6',
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        fontSize: 20,

    },
    button: {
        float: 'right',
        color: '#47ADDC',
        fontSize: 15,
        fontFamily: 'sans-serif',
        marginLeft: '25px',
        marginRight: '25px',
        variant: 'contained',
        '&:hover': {
            opacity: 0.5
        }
    },
    buttonNew: {
        float: 'right',
        color: '#47ADDC',
        fontSize: 18,
        fontFamily: 'sans-serif',
        marginLeft: '25px',
        marginTop: '10px',
        marginRight: '25px',
        textDecorationLine: 'none',
        variant: 'contained',
        '&:hover': {
            opacity: 0.5
        }
    },
    logo: {
        float: 'right',
        width: '80px',
        height: 'auto',
        marginRight: 20
    },
    logoB: {
        float: 'left',
        width: '80px',
        height: 'auto',
        paddingRight: '15px'
    },
    appBarHome: {
        width: '100%',
        margin: 0,
        position: 'absolute',
        background: '#ffff',
        boxShadow: 'none',
        height: 'auto',
    },
    mobileTxt: {
        fontFamily: 'Arial',
 
    },
    mobileTxtnew: {
        fontFamily: 'Arial',
        color: 'black',
        textDecorationLine: 'none',
        fontSize:13


    },
    toolbar: {
        paddingTop: '0px'
    },

    nextIcon: {


    },

    MenuItems: {
        backgroundColor: '#7fb5c7',
        color: '#fff',
    }

})




class AppBarMain extends Component {



    constructor(props) {
        super(props)
        this.state = {
            placeholder: [],
            anchorEl: null,
            open: false,
            id: undefined,
            menuOpen: true,
       






        }
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClick(event) {
        this.setState({ anchorEl: event.currentTarget, open: Boolean(event.currentTarget), id: "simple-popover" });


    }

    handleClose(event) {
        this.setState({ anchorEl: event.currentTarget, open: false, id: undefined });


    }


    openMenu() {
        this.setState({
            ...this.state,
            toggle: !this.state.toggle,
            menuOpen: !this.state.menuOpen,

        })
    }

    handleClick2 = () => {
        this.setState(state => ({ menuOpen: !state.menuOpen }));
    };





    render() {
        const { classes } = this.props;


        return (


            <div className={classes.root}>
                <AppBar position='relative' className={classes.appBarHome}>
                    <Toolbar className={classes.toolbar}>
                        <Link to="/">
                            <img className={classes.logo} src={logo} />
                        </Link>
                        <Link to="/">
                            <Typography className={classes.logoTxt}  >
                                Aquarius
                            </Typography>
                        </Link>
                        <Link to="/">
                            <Typography className={classes.logoTxt2}   >
                                Beauty
                            </Typography>
                        </Link>
                        <Grid container spacing={3}>


                            {/* MOBILE VERSION 2 */}
                            <Hidden lgUp>
                                <Grid item style={{ width: '100%' }} smDown={12}>
                                    {/* Toggle button to open mobile menu */}
                                    <IconButton edge='end' style={{ float: 'right', color: 'white' }}>
                                        <MenuIcon className={classes.toggle} onClick={() => { this.openMenu(this.state.toggle) }} />
                                    </IconButton>
                                </Grid>
                                <Grid item={12}>
                                    <Drawer
                                        anchor='top'
                                        open={this.state.toggle}
                                        onClose={() => { this.openMenu() }}
                                    >
                                        <MenuItem onClick={() => { window.location.replace('/') }}>
                                            <div style={{ margin: 0, paddingTop: 5, width: '100%' }}>
                                                <img className={classes.logoB} src={logo} />
                                                <Typography className={classes.mobileTxt} variant='overline'>
                                                    Aquarius Beauty
                                                </Typography>
                                                <hr />
                                            </div>
                                        </MenuItem>
                                        <MenuItem >
                                            <Link to="/" className={classes.mobileTxtnew} >
                                                <Typography variant='button'>
                                                    Home
                                                </Typography>
                                            </Link>
                                        </MenuItem>



                                     
                                            <ListItem button onClick={this.handleClick2} >


                                                <Typography style={{}} className={classes.mobileTxtnew} >
                                                    HAIR REMOVAL
                                                </Typography>
                                                {this.state.menuOpen ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.menuOpen} timeout="auto" unmountOnExit>
                                                       
                                                        <Link  className={classes.mobileTxtnew} style={{ paddingLeft:'15px', marginBottom:'50px'}} to="/WomensWaxing" >Womens Waxing     <br /></Link>
                                                        <Link  className={classes.mobileTxtnew}  style={{ paddingLeft:'15px', marginBottom:'50px'}}to="/MensWaxing" >Mens Waxing      <br /></Link>
                                                        {/* <Link  className={classes.mobileTxtnew} style={{ paddingLeft:'15px', marginBottom:'50px'}} to="/WomensWaxing" >Sugaring      <br /></Link> */}
                                                        {/* <Link className={classes.mobileTxtnew}  style={{ paddingLeft:'15px', marginBottom:'50px'}}to="/IntensePulseLightTherapy" >
                                                            IPL(Laser)   <br />  </Link> */}




                                            </Collapse>



                                        <MenuItem >
                                            <Link to="/Eyes" className={classes.mobileTxtnew} >
                                                <Typography variant='button'>
                                                    Eyes
                                                </Typography>
                                            </Link>
                                        </MenuItem>
                                        <MenuItem >
                                            <Link to="/Facials" className={classes.mobileTxtnew} >
                                                <Typography variant='button'>
                                                    Facials
                                                </Typography>
                                            </Link>
                                        </MenuItem>
                                        {/* <MenuItem >
                                            <Link to="/SkinTag" className={classes.mobileTxtnew} >
                                                <Typography variant='button'>
                                                    Skin Tags
                                                </Typography>
                                            </Link>
                                        </MenuItem> */}
                                        {/* <MenuItem >
                                            <Link to="/BodyPiercings" className={classes.mobileTxtnew} >
                                                <Typography variant='button'>
                                                    Body Piercing
                                                </Typography>
                                            </Link>
                                        </MenuItem> */}
                                        <MenuItem >
                                            <Link to="/contact" className={classes.mobileTxtnew} >
                                                <Typography variant='button'>
                                                    Contact
                                                </Typography>
                                            </Link>
                                        </MenuItem>
                                    </Drawer>
                                </Grid>
                            </Hidden>
                            {/* Desktop Version 2 */}
                            <Hidden mdDown>
                                <Grid item smUp={12} style={{ width: '100%' }}>
                                    <div style={{ width: '100%', alignItems: 'center', alignContent: 'center', height: '100%' }}>
                                        <Link className={classes.buttonNew} to="/Contact" size='large'>
                                            Contact
                                        </Link>
                                        {/* <Link className={classes.buttonNew} to="/BodyPiercings" size='large'>
                                            Body Piercings
                                        </Link> */}
                                        {/* <Link className={classes.buttonNew} to="/SkinTag" size='large'>
                                            Skin Tags
                                        </Link> */}
                                        <Link className={classes.buttonNew} to="/Facials" size='large'>
                                            Facials
                                        </Link>
                                        <Link className={classes.buttonNew} to="/Eyes" size='large'>
                                            Eyes
                                        </Link>



                                        <Link
                                            className={classes.buttonNew}
                                            aria-describedby={this.id}
                                            variant="contained"
                                            color="primary"
                                            onClick={this.handleClick}
                                        >
                                            Hair Removal
                                        </Link>
                                        <Popper id={this.id}
                                            open={this.state.open}
                                            anchorEl={this.state.anchorEl}
                                            onClose={this.handleClose}
                                            transition disablePortal>
                                            {({ TransitionProps, placement }) => (
                                                <Grow
                                                    {...TransitionProps}
                                                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                                >
                                                    <Paper>
                                                        <ClickAwayListener onClickAway={this.handleClose}>
                                                            <MenuList id="menu-list-grow" className={classes.MenuItems}  >
                                                                <MenuItem onClick={() => { window.location.replace('/WomensWaxing') }}>Womens Waxing</MenuItem>
                                                                <MenuItem onClick={() => { window.location.replace('/MensWaxing') }} >Mens Waxing</MenuItem>
                                                                {/* <MenuItem onClick={() => { window.location.replace('/WomensWaxing') }}>Sugaring</MenuItem> */}
                                                                {/* <MenuItem onClick={() => { window.location.replace('/IntensePulseLightTherapy') }}>IPL(Laser) </MenuItem> */}


                                                            </MenuList>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>
                                        <Link className={classes.buttonNew} to="/" size='large'>
                                            Home
                                        </Link>


                                    </div>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </div>
        )
    }
}
export default withStyles(styles)(AppBarMain);


