import React, { Component } from 'react';
import { withStyles, Hidden, Grid, Typography, Button } from '@material-ui/core';
import {Helmet} from "react-helmet";

//media imports

import skinTag from '../media/treatments/SkinTagNew.png'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';


import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AppBar from './appBar';
import BottomPanel from './bottomPanel';


const styles = theme => ({
  placeholder: {
    display: 'block'
  },
  root: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    flexGrow: 1,

  },
  rootMob: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    flexGrow: 1,

  },
  img: {

    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50%',
    height: '20%',
    paddingBottom: '5%',
    paddingTop: '100px'

  },
  imgMob: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
    paddingTop: '8%',
    paddingBottom: '5%',
    height: '30%',
    paddingTop: '100px'

  },
  h1Mob: {
    textAlign: 'center',
    color: '#000000',
    fontSize: 25,
    fontFamily: 'Arial',
    marginBottom: 10

  },
  bodyMob: {
    textAlign: 'center',
    color: '#000000',
    color: 'black',
    fontSize: 15,
    fontFamily: 'Arial',


  },

  h1: {
    variant: 'h1',
    color: '#000000',
    fontSize: 20,
    fontFamily: 'Arial',
    marginBottom: 10

  },
  body: {
    fontWeight: 'normal',
    vairant: 'body1',
    color: '#000000',
    fontSize: 15,
    fontFamily: 'Arial',


  },


  bookButton: {
    marginTop: '50px',
    variant: 'contained',
    backgroundColor: '#AFB6B8',
    color: '#ffff',
    width: 170,
    height: 60,
    borderRadius: 10,
    fontFamily: 'sans-serif',
    '&:hover': {
      backgroundColor: '#7fb5c7',

    }
  },
  container: {
    paddingLeft: '50%',
    paddingRight: '50%',
    width: '100%'


  },

  title: {
    textAlign: 'center',
    color: '#47ADDC',
    fontSize: 30,
    fontFamily: 'Arial',
    marginBottom: 30,
    variant: 'h1',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50%',
    height: '20%',
    paddingTop: '100px'
  },

  titleMob: {
    textAlign: 'center',
    color: '#47ADDC',
    fontSize: 30,
    fontFamily: 'Arial',
    marginBottom: 30,
    variant: 'h1',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',

    paddingTop: '100px'
  },
  heading: {
    fontFamily: 'Arial',
    fontWeight: '900',
    color: '#000000',
    fontSize:16

  },



})

class SkinTagPage extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div style={{ width: '100%', alignItems: 'center', alignContent: 'center' }} >
              <Helmet> <title>  
              Skin Tag Removal at Aquarius Beauty salon, Finsbury Park, North London
</title>
      <meta name="  Skin Tag Removal at Aquarius Beauty salon, Finsbury Park, North London" 
      content="  Skin Tag Removal at Aquarius Beauty salon, Finsbury Park, North London" />
    </Helmet>
        <AppBar />
        {/* Mobile version */}
        <Hidden mdUp>
          <div className={classes.rootMob}>
         
            <img src={skinTag} className={classes.imgMob} />
            <div >

              <h1 className={classes.h1Mob}>
              Advanced Cosmetic Procedure <br />  <br />
              </h1>
              <Typography className={classes.bodyMob}>
                This is a common skin condition. With growth, skin tags can look like a piece of soft hanging skin. They can be cosmetically unattractive, though harmless.
                Often skin tags are hereditary and they can appear on people who have no direct association with any medical condition.
                Skin tags can be removed quickly and effectively and can be carried out in all areas.


                <br />  <br />
                <br />  <br />
                <Typography className={classes.h1Mob}>
                  Prices
                </Typography>
              </Typography>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h2 className={classes.heading}>Skin Tag/Milia Removal

                  </h2>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Up to 5 minutes - £45  <br />
                    Up to 15 minutes - £90  <br />
                    Up to 30 minutes - £115  <br />

                  </Typography>
                </AccordionDetails>
              </Accordion>

              {/* <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h2 className={classes.heading}>   Milia Removal

                  </h2>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    5 minutes - £19    <br />
                    10 minutes - £23    <br />
                    15 minutes - £27    <br />

                  </Typography>
                </AccordionDetails>
              </Accordion> */}

            </div>
          </div>
        </Hidden>



        {/* DESKTOP */}
        {/* hideen on screens = to or smaller than sm( horizontal iphone ) */}
        <Hidden smDown >
          <div className={classes.root}>
          
            <img src={skinTag} className={classes.img} />
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <div className={classes.container}>

                <h1 style={{ textAlign :'center'}} className={classes.h1}>
                Advanced Cosmetic Procedure  <br />  <br />
                  </h1>
                  <Typography className={classes.body}>
                    This is a common skin condition. With growth, skin tags can look like a piece of soft hanging skin. They can be cosmetically unattractive, though harmless.
                    Often skin tags are hereditary and they can appear on people who have no direct association with any medical condition.
                    Skin tags can be removed quickly and effectively and can be carried out in all areas.




                    <br />  <br />
                    <br />  <br />
                    <Typography className={classes.h1}>
                      Prices
                    </Typography>

                  </Typography>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h2 className={classes.heading}>Skin Tag Removal/Milia Removal

                      </h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                      Up to 5 minutes - £45  <br />
                    Up to 15 minutes - £90  <br />
                    Up to 30 minutes - £115  <br />

                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  {/* <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h2 className={classes.heading}>   Milia Removal

                      </h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        5 minutes - £19    <br />
                        10 minutes - £23    <br />
                        15 minutes - £27    <br />

                      </Typography>
                    </AccordionDetails>
                  </Accordion> */}


                </div>

              </Grid>

            </Grid>


          </div>
        </Hidden>

        <Grid container spacing={0}
          direction="column"
          justify="center"
          alignItems="center">

          <Grid item xs={12}>

          <Button className={classes.bookButton} href="https://widget.treatwell.co.uk/place/32740/menu/" >
              Book Now
            </Button>
          </Grid>
        </Grid>
        <br />
        <br />

        <BottomPanel />

      </div>
    )
  }
}
export default withStyles(styles)(SkinTagPage);

