import React, { Component } from 'react';
import { withStyles, Typography, Button, Grid, Hidden } from '@material-ui/core';


//media imports


import AppBar from './appBar';
import BottomPanel from './bottomPanel';


const styles = theme => ({
    placeholder: {
        display: 'block'
    },
    root: {
        position:'relative',
        width: '100%',
        height: 'auto',
    },   
    rootMob: {
        position:'relative',
        width: '100%',
        height: 'auto',
    }, 
    title: {
        textAlign:'center',
        fontSize: 50,
        fontFamily: 'sans-serif',
        color: '#47ADDC',
        marginTop: 100,
        paddingBottom:50

    },
    titleMob: {
        textAlign:'center',
        fontSize: 30,
        fontFamily: 'sans-serif',
        color: '#47ADDC',
        marginTop: 100,
        paddingBottom:50

    },
    txtPara: {
        textAlign:'center',
        fontSize: 15,
        fontFamily: 'sans-serif',
        color: '#000000',
         
    },
    txtParaMob: {
        fontSize: 20,
        fontFamily: 'sans-serif',
        color: '#000000',
        marginTop: 5   
    },
    container: {
        width: '100%',
        paddingLeft: 100,
        paddingRight:100,
        marginTop: 20,
        marginBottom: '5%',
    },
    containerMob: {
        width: '100%',
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 10,
        marginBottom: '5%',
    },
})
     
class TermsConditions extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div style={{ width: '100%', alignItems: 'center', alignContent: 'center' }} >
                <AppBar />
                        <Grid container
                            className={classes.container}
                            spacing={0}
                            direction='row'
                            justify='center'
                            alignItems='center'>
                            {/* DESKTOP */}
                            <Hidden smDown >
                             <div className={classes.root}>
                                    <Typography className={classes.title} align='center' variant='h1' >
                                        Terms and Conditions
                                    </Typography>
                                    <Grid container spacing={3}>
                             <Grid item xs={12}>
                                    <Typography className={classes.txtPara} align='left' variant='body1' >
                                         1. Consultation and patch  test  is required before every hair removal treatment <br/>  
                                                           
                            </Typography>
                            </Grid>
                            <Grid item xs={12}>
                            <Typography className={classes.txtPara} align='center' variant='body1' >
                            2. Clients who are unable to obligate to  their appointment must cancel and reschedule appointment 
                             as soon as possible to open availability for others.   
                        </Typography>
                        </Grid>
                        <Grid item xs={12}>
                        <Typography className={classes.txtPara} align='center' variant='body1' >
                    3. Please be respectful of the time allocated to you with appointments.
                     If you are running late let us know as soon as possible.  
                        </Typography>
                        </Grid>
                        <Grid item xs={12}>

                        <Typography className={classes.txtPara} align='center' variant='body1' >
                        4. All visitors on site are advised to take care of their own belongings.
                         AquariusBeauty will not be held responsible for loss of belongings  

                        </Typography>

                        </Grid>
                        <Grid item xs={12}>

                            <Typography className={classes.txtPara} align='center' variant='body1' >
                            5. It is essential to maintain social distancing rules at all times and respect each others personal space. 
                                </Typography>

                            </Grid>
                        </Grid>
        </div>
</Hidden>
</Grid>

{/* mobile */}
<Grid container
                            className={classes.containerMob}
                            spacing={0}
                            direction='row'
                            justify='center'
                            alignItems='center'>
<Hidden mdUp >
                             <div className={classes.rootMob}>
                                    <Typography className={classes.titleMob} align='center' variant='h1' >
                                        Terms and Conditions
                                    </Typography>
                                    <Grid container spacing={3}>
                             <Grid item xs={12}>
                                    <Typography className={classes.txtParaMob} align='left' variant='body1' >
                                         1. Consultation and patch  test  is required before every hair removal treatment <br/>  
                                                           
                            </Typography>
                            </Grid>
                            <Grid item xs={12}>
                            <Typography className={classes.txtParaMob} align='center' variant='body1' >
                            2. Clients who are unable to obligate to  their appointment must cancel and reschedule appointment 
                             as soon as possible to open availability for others.   
                        </Typography>
                        </Grid>
                        <Grid item xs={12}>
                        <Typography className={classes.txtParaMob} align='center' variant='body1' >
                    3. Please be respectful of the time allocated to you with appointments.
                     If you are running late let us know as soon as possible.  
                        </Typography>
                        </Grid>
                        <Grid item xs={12}>

                        <Typography className={classes.txtParaMob} align='center' variant='body1' >
                        4. All visitors on site are advised to take care of their own belongings.
                         AquariusBeauty will not be held responsible for loss of belongings  

                        </Typography>

                        </Grid>
                        <Grid item xs={12}>

                            <Typography className={classes.txtParaMob} align='center' variant='body1' >
                            5. It is essential to maintain social distancing rules at all times and respect each others personal space. 
                                </Typography>

                            </Grid>
                        </Grid>
        </div>
</Hidden>

        </Grid>
        <BottomPanel />

          </div>
          

         
        )
    }
}
export default withStyles(styles)(TermsConditions);