import React, { Component } from 'react';
import { withStyles, Typography, Button, Grid, Hidden } from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';

//media imports


const styles = theme => ({
    placeholder: {
        display: 'block'
    },
    root: {
        width: '100%',
        // backgroundColor: '#7FB5C7',
        backgroundColor: 'white',
        position:'relative',
        flexGrow: 1,
    },
  
    title: {
        fontFamily: 'sans-serif',
        fontWeight: 'bolder',
        color: '#7FB5C7',
        position:'static',
        textAlign:'center',
        fontSize: 25,
     

    },
    openingInfo:{
        textAlign: 'center',
        color: '#7FB5C7',
        fontFamily: 'sans-serif',
        position:'static',
        fontSize: 18
    },
    socialLogo: {
      
        width: '25px',
        height: 'auto',
        color:'#7FB5C7',
    },

   

    policy:{
        textAlign: 'center',
        color: '#7FB5C7',
        fontFamily: 'sans-serif',
        textAlign:'center',
        fontSize: 15
    },
    line:{
        // backgroundColor:'#7FB5C7',
        // color:'#7FB5C7',
        // height:'1px',
        width:'85%'

    }

})

class BottomPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            placeholder: []
        }
    }
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
              <hr className={classes.line} />

                <Grid container spacing={0}
                    direction="column"
                    justify="center"
                    alignItems="center">
                   
        <Grid item xs={12}>
        <Typography className={classes.title}>
             Opening Times
       </Typography> 
        </Grid>
        <Grid item xs={12}>
        <Typography  className={classes.openingInfo}>
                            Monday: Closed
                                <br/>
                               Tuesday: 11am  - 7pm     
                               <br/>
                               Wednesday: 11am  - 7pm  
                               <br/>
                               Thursday: 11am  - 7pm  
                               <br/>
                                Friday: 10am - 6pm
                                <br/>
                               Saturday: 10am - 6pm
                               <br/>
                               Sunday: Closed
                               <br/>
                               <br/>
                            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography className={classes.policy} onClick={() => {window.location.replace('/Terms-conditions')}}>
            Terms and Conditions
            </Typography>
        </Grid>
        <Grid item xs={12}>
        <a href="https://www.instagram.com/aquariusbeautyldn/">
           <InstagramIcon className={classes.socialLogo} />
        </a>
        <a href="https://www.facebook.com/Aquarius-Beauty-332788923527441">
         <FacebookIcon className={classes.socialLogo}/>
           </a>

        </Grid>
        </Grid>
          </div>
         
        )
    }
}
export default withStyles(styles)(BottomPanel);