import React, { Component } from 'react';
import { withStyles, Grid, Hidden, Typography } from '@material-ui/core';
import AppBar from './appBar';
import BottomPanel from './bottomPanel';

import { Helmet } from "react-helmet";


const styles = theme => ({
    placeholder: {
        display: 'block'
    },
    root: {
        position: 'relative',
        width: '100%',
        height: 'auto',
    },
    title: {
        textAlign: 'center',
        fontSize: 50,
        fontFamily: 'sans-serif',
        color: '#47ADDC',
        marginTop: 100

    },
    titleMob: {
        textAlign: 'center',
        fontSize: 30,
        fontFamily: 'sans-serif',
        color: '#47ADDC',
        marginTop: 100
    },
    txtPara: {
        textAlign: 'center',
        fontSize: 20,
        fontFamily: 'sans-serif',
        color: '#000000',
        marginTop: 20,
        marginBottom: 50

    },
    txtParaMob: {
        fontSize: 20,
        fontFamily: 'sans-serif',
        color: '#000000',
        marginTop: 5
    },
    container: {
        width: '100%',
        paddingLeft: 100,
        paddingRight: 100,
        marginTop: 20,
        marginBottom: '5%',
    },
    containerMob: {
        width: '100%',
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 10,
        marginBottom: '5%',
    },
    addressTitle: {
        textAlign: 'right',
        marginTop: 0,
        fontSize: 25,
        fontFamily: 'sans-serif',
        color: '#000000',
        fontWeight: 'bold',
        position: 'absolute',
        top: '400px',
        left: '750px'
    },
    addressTxt: {
        textAlign: 'left',
        marginTop: 0,
        fontSize: 20,
        fontFamily: 'sans-serif',
        color: '#000000',
        position: 'absolute',
        top: '450px',
        left: '750px'
    },
    phoneTitle: {
        textAlign: 'right',
        marginTop: 0,
        fontSize: 25,
        fontFamily: 'sans-serif',
        color: '#000000',
        fontWeight: 'bold',
        position: 'absolute',
        top: '300px',
        left: '750px'
    },
    phoneTxt: {
        textAlign: 'right',
        marginTop: 0,
        fontSize: 20,
        fontFamily: 'sans-serif',
        color: '#000000',
        position: 'absolute',
        top: '350px',
        left: '750px'
    },
    emailTitle: {
        textAlign: 'right',
        marginTop: 0,
        fontSize: 25,
        fontFamily: 'sans-serif',
        color: '#000000',
        fontWeight: 'bold',
        position: 'absolute',
        top: '600px',
        left: '750px'
    },
    emailTxt: {
        textAlign: 'right',
        marginTop: 0,
        fontSize: 20,
        fontFamily: 'sans-serif',
        color: '#000000',
        position: 'absolute',
        top: '650px',
        left: '750px'
    },
    detailsTitleMob: {
        textAlign: 'center',
        marginTop: 0,
        fontSize: 25,
        fontFamily: 'sans-serif',
        color: '#000000',
        fontWeight: 'bold',
    },
    detailsTxtMob: {
        textAlign: 'center',
        marginTop: 0,
        fontSize: 20,
        fontFamily: 'sans-serif',
        color: '#000000',
    },
})
class Contact extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div style={{ width: '100%', alignItems: 'center', alignContent: 'center' }} >
                <Helmet> <title>  We are located in North London, two minutes walk from Finsbury Park Station</title>
                    <meta name="We are located in North London, two minutes walk from Finsbury Park Station" 
                    content="We are located in North London, two minutes walk from Finsbury Park Station" />
                </Helmet>
                <AppBar />
                <div className={classes.root}>
                    <Grid container
                        className={classes.container}
                        spacing={0}
                        direction='row'
                        justify='center'
                        alignItems='center'>
                        {/* DESKTOP */}
                        <Hidden smDown >
                            <Grid item md={12}>

                                <h1 className={classes.title} align='center' variant='h1' >
                                    Contact Us
                                </h1>
                                <h2 className={classes.txtPara} align='center' variant='body1' >
                                    Please contact us for bookings and queries.
                                </h2>

                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2480.187382178429!2d-0.10738718450385314!3d51.56479831432624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761b84d701908b%3A0x51e37a30c5ce94c!2sAquarius%20Beauty!5e0!3m2!1sen!2suk!4v1592770032089!5m2!1sen!2suk"
                                    width="600" height="450" frameborder="0" styles="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0" ></iframe>
                                <Typography className={classes.addressTitle}>
                                    Address
                                </Typography>
                                <Typography className={classes.addressTxt}>
                                    7 Stroud Green Rd,
                                    <br />
                                    Finsbury Park,
                                    <br />
                                    London
                                    <br />
                                    N4 2DQ
                                </Typography>

                                <Typography className={classes.phoneTitle}>
                                    Phone
                                </Typography>
                                <Typography className={classes.phoneTxt}>
                                    020 7263 5553 or  074 4569 9807

                                </Typography>
                                <Typography className={classes.emailTitle}>
                                    Email
                                </Typography>
                                <Typography className={classes.emailTxt}>
                                    info@aquariusbeauty.co.uk
                                </Typography>
                            </Grid>
                        </Hidden>
                    </Grid>

                    <Grid container
                        className={classes.containerMob}
                        spacing={0}
                        direction='row'
                        justify='center'
                        alignItems='center'>
                        {/* MOBILE */}
                        <Hidden mdUp>
                            <Grid smDown={12}>

                                <h1 className={classes.title} align='center' variant='h1' >
                                    Contact Us
                                </h1>

                                <h2 className={classes.txtPara} align='center' variant='body1' >
                                    Please contact us for bookings and queries.
                                </h2>

                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2480.187382178429!2d-0.10738718450385314!3d51.56479831432624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761b84d701908b%3A0x51e37a30c5ce94c!2sAquarius%20Beauty!5e0!3m2!1sen!2suk!4v1592773315386!5m2!1sen!2suk"
                                    width="350" height="500" frameborder="0" styles="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                                <Typography className={classes.detailsTitleMob}  >
                                    Phone
                                </Typography>
                                <Typography className={classes.detailsTxtMob}>
                                    020 7263 5553 or  074 4569 9807
                                </Typography>
                                <br />
                                <Typography className={classes.detailsTitleMob} >
                                    Address
                                </Typography>
                                <Typography className={classes.detailsTxtMob} >
                                    7 Stroud Green Rd,
                                    <br />
                                    Finsbury Park,
                                    <br />
                                    London
                                    <br />
                                    N4 2DQ
                                </Typography>
                                <br />

                                <Typography className={classes.detailsTitleMob}>
                                    Email
                                </Typography>
                                <Typography className={classes.detailsTxtMob}>
                                    info@aquariusbeauty.co.uk
                                </Typography>
                            </Grid>
                        </Hidden>
                    </Grid>
                </div>
                <BottomPanel />
            </div>
        )
    }
}
export default withStyles(styles)(Contact);