import React, { Component } from "react";
import { BrowserRouter, Route, Router, Link, Switch } from "react-router-dom";
import {} from "@material-ui/core";
// import './App.css';
import Home from "./components/Home";
import Contact from "./components/Contact";
// import IPL from "./components/IntensePulseLightTherapy";
import WomensWaxing from "./components/WomensWaxing";
import MensWaxing from "./components/MensWaxing";
import SkinTag from "./components/SkinTag";
import Facials from "./components/Facials";
import Eyes from "./components/Eyes";
// import BodyPiercings from './components/BodyPiercings';
import TermsConditions from "./components/Terms-Conditions";
import Popup from "./components/banner";

function App() {
  return (
    <div className="App" style={{ margin: 0, padding: 0, display: "block" }}>
      <BrowserRouter>
        <React.Fragment>
          <Route exact path="/" component={Home} />
          <Route exact path="/Contact" component={Contact} />
          {/* <Route exact path="/IntensePulseLightTherapy" component={IPL} /> */}
          <Route exact path="/WomensWaxing" component={WomensWaxing} />
          <Route exact path="/MensWaxing" component={MensWaxing} />
          <Route exact path="/SkinTag" component={SkinTag} />
          <Route exact path="/Facials" component={Facials} />
          <Route exact path="/Eyes" component={Eyes} />
          {/* <Route exact path='/BodyPiercings' component={BodyPiercings}/> */}
          <Route exact path="/Terms-Conditions" component={TermsConditions} />
        </React.Fragment>
      </BrowserRouter>
      <Popup />
    </div>
  );
}

export default App;
