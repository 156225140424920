import React, { Component } from 'react';
import { withStyles, Hidden, Grid, Typography, Button } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Helmet } from "react-helmet";

//media imports
import eyes from '../media/treatments/eyes.png'


import AppBar from './appBar';
import BottomPanel from './bottomPanel';


const styles = theme => ({
  placeholder: {
    display: 'block'
  },
  root: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    flexGrow: 1,

  },
  rootMob: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    flexGrow: 1,

  },
  img: {

    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50%',
    height: '20%',
    paddingBottom: '5%',
    paddingTop: '100px'

  },
  imgMob: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
    paddingTop: '8%',
    paddingBottom: '5%',
    height: '30%',
    paddingTop: '100px'

  },
  h1Mob: {
    textAlign: 'center',
    color: '#000000',
    fontSize: 25,
    fontFamily: 'Arial',
    marginBottom: 10

  },
  h1: {
    variant: 'h1',
    color: '#000000',
    fontSize: 20,
    fontFamily: 'Arial',
    marginBottom: 10

  },
  bodyMob: {
    textAlign: 'center',
    color: '#000000',
    color: 'black',
    fontSize: 15,
    fontFamily: 'Arial',


  },


  body: {
    fontWeight: 'normal',
    vairant: 'body1',
    color: '#000000',
    fontSize: 15,
    fontFamily: 'Arial',


  },


 
  bookButton: {
    marginTop: '50px',
    variant: 'contained',
    backgroundColor: '#AFB6B8',
    color: '#ffff',
    width: 170,
    height: 60,
    borderRadius: 10,
    fontFamily: 'sans-serif',
    '&:hover': {
      backgroundColor: '#7fb5c7',

    }
  },
  container: {
    paddingLeft: '50%',
    paddingRight: '50%',
    width: '100%'


  },

  title: {
    textAlign: 'center',
    color: '#47ADDC',
    fontSize: 30,
    fontFamily: 'Arial',
    marginBottom: 30,
    variant: 'h1',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50%',
    height: '20%',
    paddingTop: '100px'
  },

  titleMob: {
    textAlign: 'center',
    color: '#47ADDC',
    fontSize: 30,
    fontFamily: 'Arial',
    marginBottom: 30,
    variant: 'h1',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',

    paddingTop: '100px'
  },

  heading: {
    fontFamily: 'Arial',
    fontWeight: '900',
    color: '#000000',
    fontSize:16

  },

  subHeading: {
    fontFamily: 'Arial',
    fontWeight: 'normal',
    color: '#000000',
    fontSize: 16

  }
})

class EyesPage extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div style={{ width: '100%', alignItems: 'center', alignContent: 'center' }} >
        <Helmet> <title>
          Get your brows and lashes done at Aquarius Beauty in Finsbury Park, N4



        </title>
          <meta name=" Get your brows and lashes done at Aquarius Beauty in Finsbury Park, N4" 
          content=" Get your brows and lashes done at Aquarius Beauty in Finsbury Park, N4" />
        </Helmet>
        <AppBar />
        {/* Mobile version */}
        <Hidden mdUp>
          <div className={classes.rootMob}>


            <img src={eyes} className={classes.imgMob} />
            <div >
            <h1 className={classes.h1Mob}>
            Brows and Lashes
              </h1>
              <Typography className={classes.h1Mob}>
                Prices
              </Typography>
              {/* <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h2 className={classes.heading}>Brows Shaping </h2>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Brow wax - £9    <br />
                    Brow threading -  £6 <br />

                  </Typography>
                </AccordionDetails>
              </Accordion> */}

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h2 className={classes.heading}>Eyes </h2>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>

                    Brow and lash tint - £33*<br />
                    Brow tint - £18* <br />
                    Lash tint - £23*<br />
                    Brow Wax - £15<br />
                    


                  </Typography>
                </AccordionDetails>
              </Accordion>

              {/* <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h2>
                    <div className={classes.heading}>
                      Lash Lift <br />  <br />
                    </div>
                    <div className={classes.subHeading}>
                      A lash lift is a perm for your lashes so that their full length can be seen. This results in an eye-opening curl. A tint can also be added to darken the lashes so the stand out even more!
                    </div>

                  </h2>

                </AccordionSummary> 
                <AccordionDetails>
                  <Typography>
                    Lash lift - £50 <br />
                    Lash lift and tint - £60* <br />

                  </Typography>
                </AccordionDetails>

              </Accordion> */}

              {/* <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h2>
                    <div className={classes.heading}>
                      Brow Lamination <br /><br />
                    </div>

                    <div className={classes.subHeading}>
                      Also known as ‘eyebrow perm’ (without the curl)!
                      Eyebrow lamination smooths unruly hair and makes a thin brow appear full,
                      fluffy, lie straight and in the right direction.
                    </div>
                  </h2>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Brow lamination - £50 <br />
                    Brow lamination and tint - £60* <br />


                  </Typography>
                </AccordionDetails>
              </Accordion> */}
            </div>
          </div>
        </Hidden>

        {/* DESKTOP */}
        {/* hideen on screens = to or smaller than sm( horizontal iphone ) */}
        <Hidden smDown >
          <div className={classes.root}>

            <img src={eyes} className={classes.img} />
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <div className={classes.container}>
                <h1 style={{ textAlign :'center'}} className={classes.h1}>
            Brows and Lashes
              </h1>

                  <Typography className={classes.h1}>
                    Prices
                  </Typography>
                  {/* <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h2 className={classes.heading}>Brows Shaping </h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Brow wax - £9    <br />
                        Brow threading -  £6 <br />

                      </Typography>
                    </AccordionDetails>
                  </Accordion> */}

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h2 className={classes.heading}>Eyes </h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                      Brow and lash tint - £33*<br />
                    Brow tint - £18* <br />
                    Lash tint - £23*<br />
                    Brow Wax - £15<br />
                     


                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  {/* <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h2>
                        <div className={classes.heading}>
                          Lash Lift <br /> <br />
                        </div>

                        <div className={classes.subHeading}>

                          A lash lift is a perm for your lashes so that their full length can be seen. This results in an eye-opening curl. A tint can also be added to darken the lashes so the stand out even more!
                        </div>
                      </h2>

                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Lash lift - £50 <br />
                        Lash lift and tint - £60* <br />

                      </Typography>
                    </AccordionDetails>

                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h2 >
                        <div className={classes.heading}>

                          Brow Lamination<br /> <br />


                        </div>
                        <div className={classes.subHeading}>

                          Also known as ‘eyebrow perm’ (without the curl)!
                          Eyebrow lamination smooths unruly hair and makes a thin brow appear full,
                          fluffy, lie straight and in the right direction.
                        </div>

                      </h2>

                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Brow lamination - £50 <br />
                        Brow lamination and tint - £60* <br />


                      </Typography>
                    </AccordionDetails>
                  </Accordion> */}
                </div>

              </Grid>

            </Grid>


          </div>
        </Hidden>

        <Grid container spacing={0}
          direction="column"
          justify="center"
          alignItems="center">
          <Typography className={classes.note}>
          *REQUIRES PATCH TEST 24 HOURS PRIOR TO TREATMENT
                    </Typography>
          <Grid item xs={12}>

            <Button className={classes.bookButton} href="https://widget.treatwell.co.uk/place/32740/menu/" >
              Book Now
            </Button>
          </Grid>
        </Grid>
        <br />
        <br />

        <BottomPanel />

      </div>
    )
  }
}
export default withStyles(styles)(EyesPage);




  