import React, { Component } from 'react';
import { withStyles, Hidden, Grid, Typography, Button } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//media imports

import WomensWaxing2 from '../media/treatments/WomensWaxingNew.png'
import AppBar from './appBar';
import BottomPanel from './bottomPanel';
import { Helmet } from "react-helmet";


const styles = theme => ({
  placeholder: {
    display: 'block'
  },

  root: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    flexGrow: 1,

  },
  rootMob: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    flexGrow: 1,

  },
  img: {

    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50%',
    height: '20%',
    paddingBottom: '5%',
    paddingTop: '100px'

  },
  imgMob: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
    paddingTop: '8%',
    paddingBottom: '5%',
    height: '30%',
    paddingTop: '100px'

  },
  h1Mob: {
    textAlign: 'center',
    color: '#000000',
    fontSize: 25,
    fontFamily: 'Arial',
    marginBottom: 10

  },
  bodyMob: {
    textAlign: 'center',
    color: '#000000',
    color: 'black',
    fontSize: 15,
    fontFamily: 'Arial',


  },

  h1: {
    variant: 'h1',
    color: '#000000',
    fontSize: 20,
    fontFamily: 'Arial',
    marginBottom: 10

  },
  body: {
    fontWeight: 'normal',
    vairant: 'body1',
    color: '#000000',
    fontSize: 15,
    fontFamily: 'Arial',

  },

  bookButton: {
    marginTop: '50px',
    variant: 'contained',
    backgroundColor: '#AFB6B8',
    color: '#ffff',
    width: 170,
    height: 60,
    borderRadius: 10,
    fontFamily: 'sans-serif',
    '&:hover': {
      backgroundColor: '#7fb5c7',

    }
  },
  container: {
    paddingLeft: '50%',
    paddingRight: '50%',
    width: '100%',



  },

  title: {
    textAlign: 'center',
    color: '#47ADDC',
    fontSize: 30,
    fontFamily: 'Arial',
    marginBottom: 30,
    variant: 'h1',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50%',
    height: '20%',
    paddingTop: '100px'
  },

  titleMob: {
    textAlign: 'center',
    color: '#47ADDC',
    fontSize: 30,
    fontFamily: 'Arial',
    marginBottom: 30,
    variant: 'h1',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '100px'
  },
  heading: {
    fontFamily: 'Arial',
    fontWeight: '900',
    color: '#000000',
    fontSize:16

  },

})

class WomensWaxingPage extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div style={{ width: '100%', alignItems: 'center', alignContent: 'center' }} >
        <Helmet> <title>   Aquarius Beauty salon specialises in waxing for women

        </title>
          <meta name="Aquarius Beauty salon specialises in waxing for women" 
          content="Aquarius Beauty salon specialises in waxing for women" />
        </Helmet>
        <AppBar />
        {/* Mobile version */}
        <Hidden mdUp>
          <div className={classes.rootMob}>


            <img src={WomensWaxing2} className={classes.imgMob} />
            <div >

              <h1 className={classes.h1Mob}>
                Waxing For Women
              </h1>
              <Typography className={classes.bodyMob}>
                This is the process of removing hair from the root by using a wax substance to adhere to body hair and then removing by pulling the hair from the follicle.  Waxing can be done on any part of the body.
                Waxing is considered one of the most effective hair removal methods. Benefits include long lasting smooth and silky skin and the wax leaves the skin rejuvenated by removing the dead skin cells. The hair grows back softer and finer.

                <br />  <br />
                <br />  <br />

              </Typography>

              {/* <h1 className={classes.h1Mob}>
                Sugaring
              </h1>
              <Typography className={classes.bodyMob}>
                Sugaring is a natural method of hair removal using a paste made from only sugar, lemon and water.
                The vegan and biodegradable paste is gently applied to areas of unwanted hair and
                removed to extract hairs from the root.
                Sugaring is ideal for sensitive and intimate areas.<br />  <br />
                <br />  <br />
              </Typography> */}

              <Typography className={classes.h1Mob}>
                Prices
              </Typography>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h2 className={classes.heading}>Body Waxing Women  </h2>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Full leg - £39    <br />
                    Full Leg and bikini - £44   <br />
                    Full Leg and hi bikini - £49   <br />
                    Half leg - £26  <br />
                    Half leg (upper) - £31  <br />
                    Three quarter leg - £34    <br />
                    Abdomen - £29    <br />
                    Lower back - £27   <br />
                    Underarm - £24   <br />
                    Three quarter arm - £31   <br />
                    Full arm - £34  <br />
                    Half arm - £27   <br />
                    Upper lip- £9   <br />
                    Chin - £9   <br />
                    Lip and chin - £17   <br />
                    Eyebrow - £15   <br />
                    Sides of face - £18   <br />
                    Sides of face and chin - £21   <br />
                    Bikini line - £24   <br />
                    Hi bikini - £30   <br />

                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h2 className={classes.heading}>
                    Hot Wax
                  </h2>

                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  Bikini line - £29   <br />
                    Hi bikini  - £35   <br />
                    Hi bikini and underneath - £38   <br />
                    Brazilian - £43  <br />
                    Hollywood - £43   <br />
                    Hi bikini and full leg - £54   <br />
                    Hi bikini, underneath and full leg - £59  <br />
                    Brazilian and full leg  - £64  <br />
                    Hollywood and full leg - £66  <br />

                  </Typography>
                </AccordionDetails>

              </Accordion>
              {/* <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h2 className={classes.heading}>Women's Sugaring  </h2>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Bikini - £28  <br />
                    Hi Bikini - £35  <br />
                    Hi Bikini and under - £40  <br />
                    Braziian - £48  <br />
                    Hollywood - £50  <br />
                    Underarms - £22  <br />
                    Full Leg - £70  <br />
                    Half leg (lower) - £40  <br />
                    Half leg (upper) - £45  <br />
                    Full arm - £40  <br />
                    Half arm - £26  <br />
                    Upper Lip - £12  <br />
                    Chin - £12  <br />
                    Neck - £15  <br />
                    Lip and chin - £20  <br />
                    Sides of face - £20  <br />
                    Brazilian and full leg - £108  <br />
                    Hollywood and full leg - £110  <br />
                    Hi Bikini and full leg - £93  <br />
                    Hi Bikini, full leg and under - £98  <br />
                    Bikini and full leg - £86  <br />

                  </Typography>
                </AccordionDetails>
              </Accordion> */}


            </div>
          </div>
        </Hidden>



        {/* DESKTOP */}
        {/* hideen on screens = to or smaller than sm( horizontal iphone ) */}
        <Hidden smDown >
          <div className={classes.root}>


            <img src={WomensWaxing2} className={classes.img} />


            <Grid container spacing={3}>
              <Grid item xs={6}>
                <div className={classes.container}>


                <h1 style={{ textAlign :'center'}} className={classes.h1}>

                    Waxing For Women
                  </h1>
                  <Typography className={classes.body}>
                    This is the process of removing hair from the root by using a wax substance to adhere to body hair and then removing by pulling the hair from the follicle.  Waxing can be done on any part of the body.
                    Waxing is considered one of the most effective hair removal methods. Benefits include long lasting smooth and silky skin and the wax leaves the skin rejuvenated by removing the dead skin cells. The hair grows back softer and finer.

                    <br />  <br />
                    <br />  <br />

                  </Typography>

                  {/* <h1 style={{ textAlign :'center'}} className={classes.h1}>
                    Sugaring
                  </h1>
                  <Typography className={classes.body}>
                    Sugaring is a natural method of hair removal using a paste made from only sugar, lemon and water.
                    The vegan and biodegradable paste is gently applied to areas of unwanted hair and
                    removed to extract hairs from the root.
                    Sugaring is ideal for sensitive and intimate areas.
                    <br />  <br />
                    <br />  <br />
                  </Typography> */}
                  <Typography className={classes.h1}>
                    Prices
                  </Typography>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h2 className={classes.heading}>Body Waxing Women  </h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                      Full leg - £39    <br />
                    Full Leg and bikini - £44   <br />
                    Full Leg and hi bikini - £49   <br />
                    Half leg - £26  <br />
                    Half leg (upper) - £31  <br />
                    Three quarter leg - £34    <br />
                    Abdomen - £29    <br />
                    Lower back - £27   <br />
                    Underarm - £24   <br />
                    Three quarter arm - £31   <br />
                    Full arm - £34  <br />
                    Half arm - £27   <br />
                    Upper lip- £9   <br />
                    Chin - £9   <br />
                    Lip and chin - £17   <br />
                    Eyebrow - £15   <br />
                    Sides of face - £18   <br />
                    Sides of face and chin - £21   <br />
                    Bikini line - £24   <br />
                    Hi bikini - £30   <br />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h2 className={classes.heading}>
                        Hot Wax
                      </h2>

                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                    Bikini line - £29   <br />
                    Hi bikini  - £35   <br />
                    Hi bikini and underneath - £38   <br />
                    Brazilian - £43  <br />
                    Hollywood - £43   <br />
                    Hi bikini and full leg - £54   <br />
                    Hi bikini, underneath and full leg - £59  <br />
                    Brazilian and full leg  - £64  <br />
                    Hollywood and full leg - £66  <br />
                      </Typography>
                    </AccordionDetails>

                  </Accordion>
                  {/* <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h2 className={classes.heading}>Women's Sugaring  </h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Bikini - £28  <br />
                        Hi Bikini - £35  <br />
                        Hi Bikini and under - £40  <br />
                        Braziian - £48  <br />
                        Hollywood - £50  <br />
                        Underarms - £22  <br />
                        Full Leg - £70  <br />
                        Half leg (lower) - £40  <br />
                        Half leg (upper) - £45  <br />
                        Full arm - £40  <br />
                        Half arm - £26  <br />
                        Upper Lip - £12  <br />
                        Chin - £12  <br />
                        Neck - £15  <br />
                        Lip and chin - £20  <br />
                        Sides of face - £20  <br />
                        Brazilian and full leg - £108  <br />
                        Hollywood and full leg - £110  <br />
                        Hi Bikini and full leg - £93  <br />
                        Hi Bikini, full leg and under - £98  <br />
                        Bikini and full leg - £86  <br />

                      </Typography>
                    </AccordionDetails>
                  </Accordion> */}
                </div>
              </Grid>
            </Grid>
          </div>
        </Hidden>
        <Grid container spacing={0}
          direction="column"
          justify="center"
          alignItems="center">

          <Grid item xs={12}>
          <Button className={classes.bookButton} href="https://widget.treatwell.co.uk/place/32740/menu/" >
              Book Now
            </Button>
          </Grid>
        </Grid>
        <br />
        <br />
        <BottomPanel />
      </div>
    )
  }
}
export default withStyles(styles)(WomensWaxingPage);
