import React, { Component } from 'react';
import { withStyles, Hidden, Grid, Typography, Button } from '@material-ui/core';
import { Helmet } from "react-helmet";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//media imports
import Facials from '../media/treatments/FacialsNew.png'
import AppBar from './appBar';
import BottomPanel from './bottomPanel';


const styles = theme => ({
  placeholder: {
    display: 'block'
  },
  root: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    flexGrow: 1,

  },
  rootMob: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    flexGrow: 1,

  },
  img: {

    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50%',
    height: '20%',
    paddingBottom: '5%',
    paddingTop: '100px'

  },
  imgMob: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
    paddingTop: '8%',
    paddingBottom: '5%',
    height: '30%',
    paddingTop: '100px'

  },
  h1Mob: {
    textAlign: 'center',
    color: '#000000',
    fontSize: 25,
    fontFamily: 'Arial',
    marginBottom: 10,
    variant: 'h1'
  },
  bodyMob: {
    textAlign: 'center',
    color: '#000000',
    color: 'black',
    fontSize: 15,
    fontFamily: 'Arial',


  },

  h1: {
    variant: 'h1',
    color: '#000000',
    fontSize: 20,
    fontFamily: 'Arial',
    marginBottom: 10

  },
  body: {
    fontWeight: 'normal',
    vairant: 'body1',
    color: '#000000',
    fontSize: 15,
    fontFamily: 'Arial',


  },

  bookButton: {
    marginTop: '50px',
    variant: 'contained',
    backgroundColor: '#AFB6B8',
    color: '#ffff',
    width: 170,
    height: 60,
    borderRadius: 10,
    fontFamily: 'sans-serif',
    '&:hover': {
      backgroundColor: '#7fb5c7',

    }
  },


  container: {
    paddingLeft: '50%',
    paddingRight: '50%',
    width: '100%'


  },


  containerMob: {
    paddingLeft: '50px',
    paddingRight: '50px',
    width: '100%'


  },

  title: {
    textAlign: 'center',
    color: '#47ADDC',
    fontSize: 30,
    fontFamily: 'Arial',
    marginBottom: 30,
    variant: 'h1',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50%',
    height: '20%',
    paddingTop: '100px'
  },

  titleMob: {
    textAlign: 'center',
    color: '#47ADDC',
    fontSize: 30,
    fontFamily: 'Arial',
    marginBottom: 30,
    variant: 'h1',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',

    paddingTop: '100px'
  },

  heading: {
    fontFamily: 'Arial',
    fontWeight: '900',
    color: '#000000',
    fontSize:16

  },

  subHeading: {
    fontFamily: 'Arial',
    fontWeight: 'normal',
    color: '#000000',
    fontSize: 16

  }






})

class FacialsPage extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div style={{ width: '100%', alignItems: 'center', alignContent: 'center' }} >
        <Helmet> <title>
          Microdermabrasion and Dermalogica Facials at Aquarius Beauty, London N4
        </title>
          <meta name=" Microdermabrasion and Dermalogica Facials at Aquarius Beauty, London N4"
           content=" Microdermabrasion and Dermalogica Facials at Aquarius Beauty, London N4" />
        </Helmet>
        <AppBar />
        {/* Mobile version */}
        <Hidden mdUp>
          <div className={classes.rootMob}>

            <img src={Facials} className={classes.imgMob} />

            <div >

              <h1 className={classes.h1Mob}>
                Facials    <br />  <br />


                What are the benefits of a facial treatment?
              </h1>
              <Typography className={classes.bodyMob}>
                * The skin will be deep cleaned and rejuvenated.  <br />
                * Regular facials boost cell regeneration, resulting in younger looking skin.  <br />
                * Treatments can help to address a variety of skin issues.  <br />


                <br />  <br />
                <br />  <br />
                <Typography className={classes.h1Mob}>
                  Prices
                </Typography>

              </Typography>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h2 >
                    <div className={classes.heading}>
                      Dermalogica Pro 60   <br />  <br />
                    </div>
                    <div className={classes.subHeading}>
                    Targets breakouts, congestion, dryness, dehydration 
                    </div>
                  </h2>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    £70

                  </Typography>
                </AccordionDetails>
              </Accordion>


              <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h2>
                        <div className={classes.heading}>
                        Dermalogica Pro Power Peel* (Chemical Peel)  <br />  <br />
                        </div>
                        <div className={classes.subHeading}>

                        Resurface skin, smooths skin,
                        targets dark spots and hyperpigmentation 

                        
                        </div>
                      </h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        £97 <br />

                      </Typography>
                    </AccordionDetails>
                  </Accordion>

              {/* <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h2>
                    <div className={classes.heading}>
                      Dermalogica Back Facial  <br />  <br />
                    </div>
                    <div className={classes.subHeading}>

                      Effective treatment targeting back, elbows and back of upper arms.
                      Cleansing, sloughing of dead skin cells and clearing of clogged pores and nourishing the skin.
                      As well as your face and neck, your back deserves to be smooth and glowing too!
                    </div>
                  </h2>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    50 Minutes - £50 <br />

                  </Typography>
                </AccordionDetails>
              </Accordion> */}

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h2 >
                    <div className={classes.heading}>
                      Microdermabrasion  <br />  <br />
                    </div>
                    <div className={classes.subHeading}>

                    Targets fine lines, improves skin texture, reduces age  
                    spots and hyperpigmentation, brightens skin tone
                    </div>

                  </h2>

                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    £70 <br />


                  </Typography>
                </AccordionDetails>

              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h2>
                    <div className={classes.heading}>
                      Microdermabrasion and extractions   <br /><br />
                    </div>
                    <div className={classes.subHeading}>

                      Microdermabrasion and extractions for clearing clogged pores.
                    </div>

                  </h2>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    £80 <br />



                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </Hidden>
        {/* DESKTOP */}
        {/* hideen on screens = to or smaller than sm( horizontal iphone ) */}
        <Hidden smDown >
          <div className={classes.root}>

            <img src={Facials} className={classes.img} />
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <div className={classes.container}>

                <h1 style={{ textAlign :'center'}} className={classes.h1}>
                    Facials <br /> <br />
                    </h1>
                    <h1 className={classes.h1}>
                    What are the benefits of a facial treatment?
              
                  </h1>
                  <Typography className={classes.body} >
                    * The skin will be deep cleaned and rejuvenated.  <br />
                    * Regular facials boost cell regeneration, resulting in younger looking skin.  <br />
                    * Treatments can help to address a variety of skin issues.  <br />


                    <br />  <br />
                    <br />  <br />

                    <Typography className={classes.h1} >
                      Prices

                    </Typography>
                  </Typography>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h2>
                        <div className={classes.heading}>
                          Dermalogica Pro 60   <br />  <br />
                        </div>
                        <div className={classes.subHeading}>

                        Targets breakouts, congestion, dryness, dehydration 
                        </div>
                      </h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                       £70

                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h2>
                        <div className={classes.heading}>
                        Dermalogica Pro Power Peel* (Chemical Peel)  <br />  <br />
                        </div>
                        <div className={classes.subHeading}>

                        Resurface skin, smooths skin,
                        targets dark spots and hyperpigmentation 
                        </div>
                      </h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        £97 <br />

                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h2>
                        <div className={classes.heading}>
                          Microdermabrasion  <br />  <br />
                        </div>
                        <div className={classes.subHeading}>

                        Targets fine lines, improves skin texture, reduces age
                        spots and hyperpigmentation, brightens skin tone 
                        </div>


                      </h2>

                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        £70<br />


                      </Typography>
                    </AccordionDetails>

                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h2>
                        <div className={classes.heading}>

                          Microdermabrasion and extractions   <br /><br />
                        </div>
                        <div className={classes.subHeading}>

                          Microdermabrasion and extractions for clearing clogged pores.
                        </div>

                      </h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                       £80 <br />



                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>

              </Grid>

            </Grid>


          </div>
        </Hidden>

        <Grid container spacing={0}
          direction="column"
          justify="center"
          alignItems="center">
      <Typography className={classes.note}>
          *REQUIRES PATCH TEST 48 HOURS PRIOR TO TREATMENT
                    </Typography>
          <Grid item xs={12}>

          <Button className={classes.bookButton} href="https://widget.treatwell.co.uk/place/32740/menu/" >
              Book Now
            </Button>
          </Grid>
        </Grid>
        <br />
        <br />

        <BottomPanel />

      </div>
    )
  }
}
export default withStyles(styles)(FacialsPage);
