import React, { Component } from 'react';
import { withStyles, Hidden, Grid, Typography, Button } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


//media imports
import MensWaxing from '../media/treatments/MensWaxingNew.png'

import AppBar from './appBar';
import BottomPanel from './bottomPanel';
import { Helmet } from "react-helmet";

const styles = theme => ({
  placeholder: {
    display: 'block'
  },

  root: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    flexGrow: 1,

  },
  rootMob: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    flexGrow: 1,

  },
  img: {

    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50%',
    height: '20%',
    paddingBottom: '5%',
    paddingTop: '100px'

  },
  imgMob: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
    paddingTop: '8%',
    paddingBottom: '5%',
    height: '30%',
    paddingTop: '100px'

  },
  h1Mob: {
    textAlign: 'center',
    color: '#000000',
    fontSize: 25,
    fontFamily: 'Arial',
    marginBottom: 10

  },
  bodyMob: {
    textAlign: 'center',
    color: '#000000',
    color: 'black',
    fontSize: 15,
    fontFamily: 'Arial',


  },

  h1: {
    variant: 'h1',
    color: '#000000',
    fontSize: 20,
    fontFamily: 'Arial',
    marginBottom: 10

  },
  body: {
    fontWeight: 'normal',
    vairant: 'body1',
    color: '#000000',
    fontSize: 15,
    fontFamily: 'Arial',
  },
  bookButton: {
    marginTop: '50px',
    variant: 'contained',
    backgroundColor: '#AFB6B8',
    color: '#ffff',
    width: 170,
    height: 60,
    borderRadius: 10,
    fontFamily: 'sans-serif',
    '&:hover': {
      backgroundColor: '#7fb5c7',

    }
  },
  container: {
    paddingLeft: '50%',
    paddingRight: '50%',
    width: '100%'


  },

  title: {
    textAlign: 'center',
    color: '#47ADDC',
    fontSize: 30,
    fontFamily: 'Arial',
    marginBottom: 30,
    variant: 'h1',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50%',
    height: '20%',
    paddingTop: '100px'
  },

  titleMob: {
    textAlign: 'center',
    color: '#47ADDC',
    fontSize: 30,
    fontFamily: 'Arial',
    marginBottom: 30,
    variant: 'h1',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',

    paddingTop: '100px'
  },

  heading: {
    fontFamily: 'Arial',
    fontWeight: '900',
    color: '#000000',
    fontSize:16

  },



})

class MensWaxingPage extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div style={{ width: '100%', alignItems: 'center', alignContent: 'center' }} >
        <Helmet> <title>
          Men’s Waxing in Finsbury Park | Aquarius Beauty salon
        </title>
          <meta name=" Men’s Waxing in Finsbury Park | Aquarius Beauty salon" 
          content=" Men’s Waxing in Finsbury Park | Aquarius Beauty salon" />
        </Helmet>
        <AppBar />
        {/* Mobile version */}
        <Hidden mdUp>
          <div className={classes.rootMob}>

            <img src={MensWaxing} className={classes.imgMob} />
            <div >

              <h1 className={classes.h1Mob}>
                Waxing For Men
              </h1>
              <Typography className={classes.bodyMob}>
                Waxing is the removal of hair from the root, unlike shaving. The results are stubble free and smooth skin, lasting for weeks.
                We are skilled and expert wax therapists.
                Waxing doesn’t need to be painful, look no further!

                <br />  <br />
                <br />  <br />

              </Typography>

              <Typography className={classes.h1Mob}>
                Prices
              </Typography>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h2 className={classes.heading}>Men’s Waxing  </h2>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Back - £43  <br />
                    Back, neck and shoulder - £49<br />
                    Chest - £33<br />
                    Chest and abdomen - £43 <br />
                    Eyebrows - £15 <br />
                    Nostrils - £15 <br />
                    Ear lobes - £15 <br />

                  </Typography>
                </AccordionDetails>
              </Accordion>

              {/* <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h2 className={classes.heading}>  Men's Sugaring </h2>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Back - £55  <br />
                    Back and shoulders - £65  <br />
                    Lower back - £44  <br />
                    Chest - £45  <br />
                    Abdomen - £39  <br />
                    Chest and abdomen - £74  <br />

                  </Typography>
                </AccordionDetails>
              </Accordion> */}
            </div>
          </div>
        </Hidden>



        {/* DESKTOP */}
        {/* hideen on screens = to or smaller than sm( horizontal iphone ) */}
        <Hidden smDown >
          <div className={classes.root}>

            <img src={MensWaxing} className={classes.img} />
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <div className={classes.container}>

                <h1 style={{ textAlign :'center'}} className={classes.h1}>
                    Waxing For Men
                  </h1>
                  <Typography className={classes.body}>
                    Waxing is the removal of hair from the root, unlike shaving. The results are stubble free and smooth skin, lasting for weeks.
                    We are skilled and expert wax therapists.
                    Waxing doesn’t need to be painful, look no further!

                    <br />  <br />
                    <br />  <br />

                  </Typography>

                  <Typography className={classes.h1}>
                    Prices
                  </Typography>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h2 className={classes.heading}>Men’s Waxing  </h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                      Back - £43  <br />
                    Back, neck and shoulder - £49<br />
                    Chest - £33<br />
                    Chest and abdomen - £43 <br />
                    Eyebrows - £15 <br />
                    Nostrils - £15 <br />
                    Ear lobes - £15 <br />

                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  {/* <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h2 className={classes.heading}>  Men's Sugaring </h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Back - £55  <br />
                        Back and shoulders - £65  <br />
                        Lower back - £44  <br />
                        Chest - £45  <br />
                        Abdomen - £39  <br />
                        Chest and abdomen - £74  <br />

                      </Typography>
                    </AccordionDetails>
                  </Accordion> */}

                </div>

              </Grid>

            </Grid>


          </div>
        </Hidden>

        <Grid container spacing={0}
          direction="column"
          justify="center"
          alignItems="center">

          <Grid item xs={12}>

          <Button className={classes.bookButton} href="https://widget.treatwell.co.uk/place/32740/menu/" >
              Book Now
            </Button>
          </Grid>
        </Grid>
        <br />
        <br />

        <BottomPanel />

      </div>
    )
  }
}
export default withStyles(styles)(MensWaxingPage);
