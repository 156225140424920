import React, { Component } from 'react';
import { withStyles, Grid, Hidden, Typography, Button } from '@material-ui/core';
import { Helmet } from "react-helmet";

//media imports
import homePic from '../media/home/HomeNew.png'
import AppBar from './appBar';
import BottomPanel from './bottomPanel';

const styles = theme => ({
    placeholder: {
        display: 'block'
    },

    root: {
        position: 'relative',
        width: '100%',
        height: '1500px',
    },
    rootMob: {
        position: 'relative',
        width: '100%',
        height: 'auto',
    },

    img: {

        width: '100%',
        height: '600px',

    },
    imgMob: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '90%',
        paddingTop: '8%',
        paddingBottom: '5%',
        height: '20%',
        paddingTop: '100px'

    },

    title: {
        textAlign: 'center',
        color: '#47ADDC',
        fontSize: 30,
        fontFamily: 'Arial',
        marginBottom: 30
    },

    h1: {
        textAlign: 'center',
        variant: 'h1',
        fontWeight: '400',
        color: '#000000',
        fontSize: 25,
        fontFamily: 'Arial',
        marginBottom: 10

    },
    body: {
        textAlign: 'center',
        marginLeft: '150px',
        marginRight: '150px',
        fontWeight: 'normal',
        vairant: 'body1',
        color: '#000000',
        fontSize: 20,
        fontFamily: 'Arial',


    },
    h1Mob: {
        textAlign: 'center',
        color: '#000000',
        fontSize: 25,
        fontFamily: 'Arial',
        marginBottom: 30

    },
    bodyMob: {
        textAlign: 'center',
        color: '#000000',
        color: 'black',
        fontSize: 18,
        fontFamily: 'Arial',
    },

    treatmentsMob: {
        fontFamily: 'Arial',
        textAlign: 'center',
        maxWidth: '200px',
        maxHeight: '45px',
        minWidth: '200px',
        minHeight: '45px',
        margin: theme.spacing(1),
        color: 'black',
        size: 'small',
        fontFamily: 'Arial',
        textAlign: 'center',
    },
    treatments: {
        maxWidth: '200px',
        maxHeight: '50px',
        minWidth: '200px',
        minHeight: '50px',
        borderColor: "#47ADDC",
        margin: theme.spacing(1),
        color: 'black',
        size: 'small',
        fontFamily: 'Arial',
        textAlign: 'center',
        '&:hover': {
            color: '#47ADDC',
            borderColor: "#47ADDC",
        }
    },
    wrapper: {
        maxWidth: '500px',
        margin: 'auto',
        overflow: 'hidden',
    },
    treatmentsBody1: {
        margin: '10px',
        padding: ' 20px',
        width: '190px',
        float: 'left',
    },
    treatmentsBody2: {
        margin: '10px',
        padding: ' 20px',
        width: '190px',
        float: 'right',
    },
    treatmentsBody3: {
        margin: '10px',
        padding: ' 20px',
        width: '190px',
    },
    bookButton: {
        marginTop: '50px',
        marginBottom: '100px',
        variant: 'contained',
        backgroundColor: '#AFB6B8',
        color: '#ffff',
        width: 170,
        height: 60,
        borderRadius: 10,
        fontFamily: 'sans-serif',
        '&:hover': {
            backgroundColor: '#7fb5c7',

        }
    },

})

class Splash extends Component {

    render() {
        const { classes } = this.props;
        return (

            <div style={{ width: '100%', alignItems: 'center', alignContent: 'center' }}>
                <Helmet> <title>
                    We are a beauty salon located in Finsbury Park, North London, N4 2DQ
                </title>
                    <meta name="We are a beauty salon located in Finsbury Park, North London, N4 2DQ" 
                    content="We are a beauty salon located in Finsbury Park, North London, N4 2DQ" />
                </Helmet>
                {/* Top app bar (transparent on home) */}
                <AppBar position="fixed" />
                {/* Mobile version */}
                <Hidden mdUp>
                    <div className={classes.rootMob}>

                        <img src={homePic} className={classes.imgMob} />
                        <br />
                        <br />

                        <h1 className={classes.title}>
                            Beauty Salon Services
                        </h1>
                        <Typography className={classes.bodyMob}>
                            Welcome to Aquarius Beauty!    <br />     <br />
                            We are a beauty salon based in Finsbury Park, North London and have been here for 16 years.    <br />
                            Offering a variety of beauty treatments to high standards, our services include:    <br />     <br />

                        </Typography>
                        <Typography align='center'>

                            <Button href="/WomensWaxing" variant="outlined" color="primary" className={classes.treatmentsMob}>  Women's Waxing    </Button>
                            <Button href="/MensWaxing" variant="outlined" color="primary" className={classes.treatmentsMob}>   Men's Waxing    </Button>
                            {/* <Button href="/IntensePulseLightTherapy" variant="outlined" color="primary" className={classes.treatmentsMob}>  Intense Pulse Light Therapy (IPL)</Button> */}
                            {/* <Button href="/WomensWaxing" variant="outlined" color="primary" className={classes.treatmentsMob}>   Sugaring    </Button> */}
                            <Button href="/Eyes" variant="outlined" color="primary" className={classes.treatmentsMob}>   Eyes  </Button>
                            <Button href="/Facials" variant="outlined" color="primary" className={classes.treatmentsMob}>   Facials     </Button>
                            <Button href="/SkinTag" variant="outlined" color="primary" className={classes.treatmentsMob}>   Skin Tag    </Button>
                            {/* <Button href="/BodyPiercings" variant="outlined" color="primary" className={classes.treatmentsMob}>   Body Piercing   </Button> */}

                        </Typography>

                        <Typography className={classes.bodyMob}>
                            <br />     <br />
                            Our beauty therapists have between 15 - 25 years of experience and we are proud of the reputation we have built, our skills are unparalleled.
                            This is reflected in client feedback where the reviews consistently affirm customer satisfaction with our beauty salon.
                            Our waxing services are second to none.
                            We have also served as a training centre for beauty therapists, who want to specialise in Brazilian and Hollywood waxing for any gender.

                            We use superior hot and strip waxes,  achieving high quality results and  which is also suitable for sensitive and delicate areas.
                            Skin care product range  include Dermalogica and Eve Taylor which are both gentle and result oriented.
                            We are a friendly team and it is our aim to provide a quality service to every client in a professional, clean and safe environment.

                            Get in touch, we are happy to help!


                        </Typography>

                    </div>
                </Hidden>

                {/* DESKTOP */}
                <Hidden smDown >
                    <div className={classes.root}>

                        <img src={homePic} className={classes.img} />
                        <br />
                        <br />
                        <br />

                        <h1 className={classes.title}>
                            Beauty Salon Services

                        </h1>

                        <Typography className={classes.body}>
                            Welcome to Aquarius Beauty!    <br />     <br />
                            We are a beauty salon based in Finsbury Park, North London and have been here for 16 years.    <br />
                            Offering a variety of beauty treatments to high standards, our services include:    <br />     <br />

                        </Typography>

                        <div className={classes.wrapper}>

                            <div className={classes.treatmentsBody1}>
                                <Button href="/WomensWaxing" variant="outlined" color="primary" className={classes.treatments} >  Women's Waxing     </Button>
                                <Button href="/MensWaxing" variant="outlined" color="primary" className={classes.treatments} >   Men's Waxing     </Button>
                                {/* <Button href="/IntensePulseLightTherapy" variant="outlined" color="primary" className={classes.treatments} >  Intense Pulse Light Therapy (IPL)  <br /> </Button> */}
                                {/* <Button href="/WomensWaxing" variant="outlined" color="primary" className={classes.treatments}>   Sugaring        </Button> */}
                                <Button href="/SkinTag" variant="outlined" color="primary" className={classes.treatments}>   Skin Tag  </Button>

                            </div>
                            <div className={classes.treatmentsBody2}>
                                <Button href="/Eyes" variant="outlined" color="primary" className={classes.treatments}>  Eyes   </Button>
                                <Button href="/Facials" variant="outlined" color="primary" className={classes.treatments}>   Facials     </Button>
                                {/* <Button href="/BodyPiercings" variant="outlined" color="primary" className={classes.treatments}>   Body Piercing   </Button> */}

                            </div>
         
                        </div>
                        <Typography className={classes.body}>

                            Our beauty therapists have between 15 - 25 years of experience and we are proud of the reputation we have built, our skills are unparalleled.
                            This is reflected in client feedback where the reviews consistently affirm customer satisfaction with our beauty salon.
                            Our waxing services are second to none.
                            We have also served as a training centre for beauty therapists, who want to specialise in Brazilian and Hollywood waxing for any gender.

                            We use superior hot and strip waxes,  achieving high quality results and  which is also suitable for sensitive and delicate areas.
                            Skin care product range  include Dermalogica and Eve Taylor which are both gentle and result oriented.
                            We are a friendly team and it is our aim to provide a quality service to every client in a professional, clean and safe environment.

                            Get in touch, we are happy to help!


                        </Typography>
                    </div>
                </Hidden>
                <Grid container spacing={0}
                    direction="column"
                    justify="center"
                    alignItems="center">

                    <Grid item xs={12}>
                    <Button className={classes.bookButton} href="https://widget.treatwell.co.uk/place/32740/menu/" >
              Book Now
            </Button>
                    </Grid>
                </Grid>
                <BottomPanel />
            </div>
        )
    }
}
export default withStyles(styles)(Splash);
