import React, { Component } from 'react';
import { withStyles, Button, Grid, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';

import logo from '../Ab logo copy.png'



const styles = theme => ({
    placeholder: {
        display: 'block'
    },
    button: {
        marginTop: '30px',
        margingBottom: '30px',
        borderRadius: 2,
        width: '200px',
        fontFamily: 'sans-serif',
        fontWeight: 'light',
        backgroundColor: 'black',
        '&:hover': {
            backgroundColor: '#1e3d7a',
        },
        color: 'white',
        borderRadius: 2
    },
    font: {
        fontFamily: 'sans-serif'
    },
    fontBold: { 
        fontFamily: 'sans-serif',
        fontWeight: 'bold',

    }
})

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


class popUp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false
        }
    }
    // Handles the dialog pop up timer
    componentDidMount() {
        setTimeout(() => { this.openPopUp() }, 10000)
    }

    openPopUp(){
        if (!sessionStorage.getItem('AquariusBeauty') || sessionStorage.getItem('AquariusBeauty') === false){

        this.setState({
                ...this.state,
                open: true
            }, sessionStorage.setItem('AquariusBeauty', true))
        }
    }

    handleClose() {
        this.setState({
            ...this.state,
            open: false
        }, sessionStorage.setItem('Rhipz popup', false))
    }
    render() {
        const { classes, Transition } = this.props;
        return (
            <div>
                <Dialog
                    open={this.state.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => { this.handleClose() }}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle  >
                <img src={logo}  />
                        
                    </DialogTitle>

                    <Grid container
                        spacing={2}
                        justify='center'
                        direction='row'>
                        {/* Dialog Content */}
                        <DialogContent>
                     
                        <Grid item xs={12}>
                            <DialogContentText className={classes.font} id="alert-dialog-slide-description">
                            In order to protect the health and well being of our clients and staff we are following government guidelines.
           
                            <br/>  
                            <br/>  
                            Stay safe and we look forward to seeing you soon.
                            </DialogContentText>
                        </Grid>
                       
                        </DialogContent>
                       
                      
                    </Grid>
                </Dialog>
            </div>
        )
    }
}
export default withStyles(styles)(popUp);